import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dashboardIcon from '../Assets/Images/filter.svg';
import dashboardIconActive from '../Assets/Images/filteActive.svg';
import historyIcon from '../Assets/Images/folder.svg';
import historyIconActive from '../Assets/Images/folderActive.svg';
import settingsIcon from '../Assets/Images/settings.svg';
import settingsIconActive from '../Assets/Images/settingsActive.svg';
import signOutIcon from '../Assets/Images/signout.svg';
import signOutIconActive from '../Assets/Images/signout.svg';
import hamburgerIcon from '../Assets/Images/hamburga.png';
import { toast } from "react-toastify";
import logoImg from "../Assets/Images/logo.png";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar open/close

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    // Remove auth token from local storage
    localStorage.removeItem('authToken');
    toast.success("Log Out Successful")

    // Redirect to login or home page
    navigate('/');
  };

  const getLinkClass = (path: string) => {
    return location.pathname === path 
      ? "flex items-center p-2 py-4 text-white bg-blue-500 rounded-xl w-[256px] pl-6 transition duration-300"
      : "flex items-center p-2 py-4 text-gray-700 hover:bg-gray-200 rounded-xl w-[256px] transition duration-300";
  };

  const getIcon = (path: string, defaultIcon: string, activeIcon: string) => {
    return location.pathname === path ? activeIcon : defaultIcon;
  };

  return (
    <div className="md:w-[320px] w-full md:left-0 top-0 bg-white z-10 md:fixed">
      <div className="flex flex-col md:w-64 w-full p-4 h-full">
        {/* Hamburger Icon for Mobile */}
        <div className="flex md:hidden justify-between items-center">
          <h1 className="logoTextAferAuth text-[#0066FF] block pt-6 md:py-12 pb-4 md:hidden">
          
            <span className="md:text-[54px] md:w-[197px] text-center">
            <div className="flex justify-center items-center w-40 h-40 overflow-hidden">
              <img
                src={logoImg}
                alt="logo image"
                className="object-contain w-full h-full"
              />
            </div>
              {/* ai<span className="text-black">de</span>ja */}
              </span>
          </h1>
          <img src={hamburgerIcon} alt="Menu" className="cursor-pointer w-12 h-12" onClick={toggleSidebar} />
        </div>


        <h1 className="logoText text-[#0066FF] md:block mx-auto pr-4 py-12 pb-8 hidden">
          <span className="text-[54px] w-[197px] text-center">
          <div className="flex justify-center items-center w-40 h-40  overflow-hidden">
              <img
                src={logoImg}
                alt="logo image"
                className="object-contain w-full h-full"
              />
            </div>
            {/* ai<span className="text-black">de</span>ja */}
            </span>
        </h1>
        {/* Sidebar Content */}
        <nav className={`space-y-6 bg-[#FFFFFF] md:block absolute inset-y-0 left-0 z-20 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 md:block transition-transform duration-300 ease-in-out pt-6 md:pt-0`}>
          {/* Logo */}
          {isOpen && (
            <div className="flex items-center justify-center mb-8 md:mb-0">
              <h1 className="logoTextAferAuth text-[#0066FF] md:hidden mx-auto pr-4 py-6 pb-4 md:py-12 md:pb-8">
                <span className="md:text-[54px] md:w-[197px] text-center">
                <div className="flex justify-center items-center w-40 h-40  overflow-hidden">
              <img
                src={logoImg}
                alt="logo image"
                className="object-contain w-full h-full"
              />
            </div>
                  {/* ai<span className="text-black">de</span>ja */}
                  </span>
              </h1>
            </div>
          )}

          {/* Links */}
          <Link
            to="/dashboard"
            className={getLinkClass("/dashboard")}
            onClick={toggleSidebar}
          >
            <img src={getIcon("/dashboard", dashboardIcon, dashboardIconActive)} alt="Dashboard Icon" className="mr-3 w-[32px] h-[32px]" />
            <span className="text-[18px]"> Dashboard</span>
          </Link>
          <Link
            to="/dashboard/history"
            className={getLinkClass("/dashboard/history")}
            onClick={toggleSidebar}
          >
            <img src={getIcon("/dashboard/history", historyIcon, historyIconActive)} alt="History Icon" className="mr-3 w-[32px] h-[32px]" />
            <span className="text-[18px]"> History</span>
          </Link>
          <Link
            to="/dashboard/settings"
            className={getLinkClass("/dashboard/settings")}
            onClick={toggleSidebar}
          >
            <img src={getIcon("/dashboard/settings", settingsIcon, settingsIconActive)} alt="Settings Icon" className="mr-3 w-[32px] h-[32px]" />
            <span className="text-[18px]"> Settings </span>
          </Link>
          <div
            className={`${getLinkClass("/signout")} cursor-pointer`}
            onClick={handleSignOut}
          >
            <img src={getIcon("/signout", signOutIcon, signOutIconActive)} alt="Sign Out Icon" className="mr-3 w-[32px] h-[32px]" />
            <span className="text-[18px]"> Sign Out</span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
