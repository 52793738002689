import React from "react";
import { Link } from "react-router-dom";
import checkImage from "../Assets/Images/check.png"; // Ensure this import path is correct

const PasswordConfirmation: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white p-8 rounded text-center max-w-md mx-auto">
        <div className="mx-auto mb-4 w-[270px] h-[270px] border-[3.29px] border-[#4CD964] rounded-full bg-[#E0FFE5] flex items-center justify-center">
          <div className="rounded-full w-[164px] h-[164px] bg-[#4CD964] flex items-center justify-center">
            <img
              src={checkImage}
              alt="Email Confirmation"
              className="w-[69px] h-[69px]"
            />
          </div>
        </div>
        <h1 className="text-4xl mt-16 h-[55px] w-full text-nowrap" style={{ fontWeight: 950 }}>
          Forgot Reset Successful
        </h1>
        <p className="mb-8 font-[400]">
          Your password reset was successful, kindly click the button below to
          login
        </p>
        <div className="bg-[#0066FF] text-white text-center px-4 py-2 rounded-[32px] w-[256px] h-[64px] hover:bg-blue-600 mx-auto flex items-center justify-center cursor-pointer">
          <Link
            to={"/"} // Change this to the appropriate route
          >
            Back To Login Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordConfirmation;
