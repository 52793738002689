import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import FilteredResultsPage from "./AllFurnitures"; // Correct path and component name
import GenerateVisualization from "./GenerateVisualization";
import HistoryPage from "../pages/HistoryPage";
import HistoryDetail from "./HistoryDetails";


const MainLayout: React.FC = () => {
  
  const isAuthenticated = !!localStorage.getItem("authToken");

  return (
    <div className="flex md:flex-row flex-col min-h-screen max-w-screen-2xl bg-[#F8F8F8] w-full my-0">
      <div className="w-full md:w-[320px] bg-white left-0 top-0 z-10">
        <SideBar />
      </div>
      <div className="flex-1 md:p-8 p-4 md:justify-items-end justify-center h-full ">
        {isAuthenticated ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
            <Route path="/filtered-results" element={<FilteredResultsPage />} />
            <Route path="/visualize" element={<GenerateVisualization />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/view/:id" element={<HistoryDetail />} />
          </Routes>
        ) : (
          <Navigate to="/" />
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
