import React, { useState, useEffect, useRef, CSSProperties } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // Correct import for jwt_decode
import goggle from "../Assets/Images/goggleLogo.png";
import registerImg from "../Assets/Images/registerImg.png";
import { API_URL } from "../constants";
import { useGoogleLogin } from "@react-oauth/google";
import logoImg from "../Assets/Images/logo.png";
const Register: React.FC = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [googleButtonStyles, setGoogleButtonStyles] = useState<CSSProperties>({});

  const googleButtonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();



  // useEffect(() => {
  //   const initializeGoogleSignIn = () => {
  //     if (!window.google) {
  //       console.error("Google script is not loaded");
  //       return;
  //     }

  //     window.google.accounts.id.initialize({
  //       client_id: GOOGLE_CLIENT_ID,
  //       callback: handleGoogleResponse,
  //     });

  //     window.google.accounts.id.renderButton(googleButtonRef.current, {
  //       theme: "outline",
  //       size: "large",
  //       text: "continue_with",
  //       shape: "pill",
  //     });
  //   };

  //   const script = document.createElement("script");
  //   script.src = "https://accounts.google.com/gsi/client";
  //   script.async = true;
  //   script.defer = true;
  //   script.onload = () => {
  //     initializeGoogleSignIn();
  //   };
  //   script.onerror = () => {
  //     console.error("Google script failed to load");
  //   };
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [GOOGLE_CLIENT_ID]);

  const updateGoogleButtonStyles = () => {
    if (window.innerWidth < 500) {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "0%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    } else if (window.innerWidth < 700) {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "28%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    } else {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "28%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    }
  };

  useEffect(() => {
    updateGoogleButtonStyles();
    window.addEventListener("resize", updateGoogleButtonStyles);
    return () => {
      window.removeEventListener("resize", updateGoogleButtonStyles);
    };
  }, []);

  const handleGoogleResponse = async (response: any) => {
    const userObject: any = jwtDecode(response.credential); // Correct usage of jwt_decode

    const payload = {
      displayName: userObject.name,
      email: userObject.email,
      id: userObject.sub,
      photoUrl: userObject.picture,
    };

    try {
      const googleResponse = await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/google",
        payload
      );

      const { token, user } = googleResponse.data.data;

      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user));

      toast.success("Google Sign-In successful");
      navigate("/dashboard");
    } catch (error: any) {
      const errorMessage = error.response?.data?.error?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const validatePassword = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isLengthValid || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar) {
      toast.error("Password does not meet all requirements.");
      setIsLoading(false);
      return;
    }

    if (!firstname || !lastname || !email || !password || !reEnterPassword) {
      toast.error("Please fill in all fields");
      setIsLoading(false);
      return;
    }

    if (password !== reEnterPassword) {
      toast.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      setIsLoading(false);
      return;
    }

    const requestData = { firstname, lastname, email, password };

    try {
      const response = await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/register",
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );

      const { token, user } = response.data.data;
      localStorage.setItem("authToken", token);

      toast.success(response.data.message);
      navigate(
        `/email_confirmation?email=${encodeURIComponent(
          email
        )}&verificationType=emailVerification`
      );
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.error?.message || "An error occurred");
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const clickGoogleSignIn = useGoogleLogin({
    flow: 'auth-code',
    scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: async (codeResponse:any) => {
      console.log('codeResponse');
      console.log(codeResponse);
      const response = await axios.post(
        `${API_URL}/auth/google`, {
        ...codeResponse,
      });

      let token = response.data.data.token;
      let user = response.data.data.user;
      console.log('tokens');
      console.log(token);

      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user.firstname));

      navigate("/dashboard");
      toast.success("Login successful");
    },
    onError:( errorResponse) => {
      
      
      console.log(errorResponse)
      toast.error("Login successful");
    },
  });

  return (
    <div className="min-h-screen flex max-w-screen-2xl">
      <div className="flex-1 flex justify-center items-center bg-gray-100 w-full">
        <div className="bg-white p-8 rounded shadow-md w-full h-full py-7 lg:px-24 px-8">
          <h1 className="logoText text-[#0066FF;]">
          <div className="flex justify-center items-center w-40 h-40  overflow-hidden">
              <img
                src={logoImg}
                alt="logo image"
                className="object-contain w-full h-full"
              />
            </div>
            {/* ai<span className="text-black">de</span>ja */}
          </h1>
          <h1 className="text-3xl font-extrabold text-left mb-2">
            Welcome to aideja
          </h1>
          <p className="text-left mb-4">
            Already have an account?{" "}
            <Link to={"/"} className="text-blue-500">
              Log in
            </Link>
          </p>
          <div className="relative w-full flex items-center justify-center px-3 py-3 rounded-[40px] mb-4 bg-white custom-button-border text-black h-[64px] transition-colors duration-300 ease-in-out hover:bg-gray-800 hover:text-white">
  <div
    ref={googleButtonRef}
    data-text="Sign in with Google"
    id="google-signin-button"
    style={googleButtonStyles}
    onClick={() => clickGoogleSignIn()}
    className="absolute inset-0 z-10" // Ensures it's above other content
  ></div>
  <div className="flex items-center space-x-2"> {/* Flex container for icon and text */}
    <img src={goggle} alt="Google Icon" className="w-6 h-6" />
    <span>Continue With Google</span>
  </div>
</div>
          <div className="flex items-center my-4 pt-2">
            <div className="flex-grow border-t border-[#66666640] border-[2px]"></div>
            <span className="mx-4 text-gray-500">OR</span>
            <div className="flex-grow border-t border-[2px] border-[#66666640]"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-full">
              <div className="flex mb-4 w-full">
                <div className="flex-1 px-1 basis-1/2">
                  <label className="block text-[#666666] mb-1 text-left font-normal">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
                <div className="flex-1 px-1 basis-1/2">
                  <label className="block text-[#666666] mb-1 text-left font-normal">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-[#666666] mb-1 text-left font-normal">
                Email
              </label>
              <input
                type="email"
                className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="">
              <div className="bg-white rounded text-center">
                <div className="">
                  <div>
                    <div className="flex items-center justify-between mt-2">
                      <label className="block text-[#666666] mb-1 text-left font-normal">
                        New Password
                      </label>
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="text-gray-500 bg-white my-0"
                      >
                        <div className="flex ">
                          <span className="pt-1">
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </span>
                          <span className="mx-2 ">
                            {passwordVisible ? "Hide" : "Show"}
                          </span>
                        </div>
                      </button>
                    </div>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        validatePassword(e.target.value);
                      }}
                    />
                  </div>

                  <div>
                    <div className="flex items-center justify-between mt-6">
                      <label className="block text-[#666666] mb-1 text-left font-normal">
                        Re-enter Password
                      </label>
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="text-gray-500 bg-white my-0"
                      >
                        <div className="flex ">
                          <span className="pt-1">
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </span>
                          <span className="mx-2 ">
                            {passwordVisible ? "Hide" : "Show"}
                          </span>
                        </div>
                      </button>
                    </div>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                      value={reEnterPassword}
                      onChange={(e) => setReEnterPassword(e.target.value)}
                    />
                  </div>
                  <div className="text-gray-500 text-sm mb-4">
                    <div className="md:flex justify-between md:p-2  pt-2">
                      <div className="flex items-center">
                        <span
                          className={`dot ${
                            isLengthValid ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></span>
                        <p className="ml-2">Use 8 or more characters</p>
                      </div>
                      <div className="flex items-center">
                        <span
                          className={`dot ${
                            hasUpperCase ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></span>
                        <p className="ml-2">One Uppercase character</p>
                      </div>
                      <div className="flex items-center">
                        <span
                          className={`dot ${
                            hasLowerCase ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></span>
                        <p className="ml-2">One Lowercase character</p>
                      </div>
                    </div>
                    <div className="md:flex md:space-x-4 md:p-2">
                      <div className="flex items-center">
                        <span
                          className={`dot ${
                            hasNumber ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></span>
                        <p className="ml-2">One number</p>
                      </div>
                      <div className="flex items-center md:pl-28">
                        <span
                          className={`dot ${
                            hasSpecialChar ? "bg-green-500" : "bg-gray-300"
                          }`}
                        ></span>
                        <p className="ml-2">One special character</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center mb-4">
              <input type="checkbox" id="terms" className="mr-2" />
              <label htmlFor="terms" className="text-gray-500">
                I agree to the{" "}
                <Link to="/terms" className="text-blue-500">
                  Terms and Conditions
                </Link>
              </label>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className={`w-[256px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[64px] ${
                isLoading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#0066FF] hover:bg-[#0056b3]"
              }`}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </form>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-200 max-w-md">
        <img
          src={registerImg}
          alt="Register"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default Register;
