import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { API_URL } from "../constants";

interface HistoryEntry {
  _id: string;
  name: string;
  furnitures: FurnitureDetails[]; // Array of furniture details
  generatedImage?: string;
  createdAt: string;
}

interface FurnitureDetails {
  _id: string;
  sku: string;
  title: string;
  url: string;
  imageUrl: string[];
  style?: string; // Added to match your data structure
  color?: string; // Added color property
}

const History: React.FC = () => {
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);

  const fetchProjectDetails = async (projectId: string, token: string) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/user/project/${projectId}`,
        { headers: { Authorization: token } }
      );
      return data.data;
    } catch (error) {
      console.error(`Error fetching project details for ID ${projectId}:`, error);
      return null;
    }
  };

  const fetchHistory = useCallback(async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No auth token found");
      return;
    }

    setLoading(true);

    try {
      // Fetch history
      const { data: historyData } = await axios.get(
        `${API_URL}/user/projects`,
        { headers: { Authorization: token } }
      );
      const serverHistory: HistoryEntry[] = historyData.data || [];

      console.log("Fetched history:", serverHistory);

      // Fetch detailed project data including furniture details
      const detailedHistory = await Promise.all(
        serverHistory.map(async (entry) => {
          const projectDetails = await fetchProjectDetails(entry._id, token);
          return {
            ...entry,
            furnitures: projectDetails ? projectDetails.furnitures : [],
          };
        })
      );

      console.log("Detailed history:", detailedHistory);

      setHistory(detailedHistory);

    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  const totalPages = Math.ceil(history.length / itemsPerPage);

  const handlePageChange = (direction: 'previous' | 'next') => {
    if (direction === 'previous' && currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const currentItems = history.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="min-h-screen flex flex-col items-center py-4 w-full h-full bg-[#F8F8F8]">
      <div className="text-start w-full max-w-screen-lg px-4 sm:px-6 lg:px-3">
        <h1 className="text-[40px] font-[800] mb-6 text-start">History</h1>
      </div>
      {loading ? (
        <div className="flex justify-center items-center w-full h-full">
          <ClipLoader color="#123abc" loading={loading} size={70} />
        </div>
      ) : history.length > 0 ? (
        currentItems.map(entry => (
          <div key={entry._id} className="bg-white p-4 rounded-lg shadow-lg mb-4 w-full max-w-screen-lg">
            <div className="mb-2">
              <strong className="text-xl">Room:</strong>
              <span className="font-extrabold text-[#112660] text-xl">{entry.name}</span>
            </div>
            <div className="mb-2 lg:flex align-middle">
              <strong className="text-lg mr-2">Furniture(s):</strong>
              <div className="flex flex-wrap mt-1">
                {entry.furnitures.map(furniture => (
                  <div key={furniture._id} className="mb-2">
                    <span className="block bg-gray-200 p-2 rounded text-base">
                      {furniture.title || "Title not available"}
                      {furniture.style && ` (${furniture.style})`}
                      {furniture.color && (
                        <span className="block mt-1 text-base">
                          <strong className="font-bold">Color:</strong> {furniture.color}
                        </span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between items-center mt-4">
              {entry.generatedImage && (
                <img
                  src={entry.generatedImage}
                  alt="Generated Room"
                  className="w-32 h-32 object-cover rounded-lg"
                />
              )}
              <Link
                to={`/dashboard/view/${entry._id}`}
                className="bg-[#0066FF1A] text-[#0066FF] px-4 py-2 rounded-lg shadow hover:bg-blue-700 hover:text-white transition ease-in-out duration-150"
              >
                View more
              </Link>
            </div>
          </div>
        ))
      ) : (
        <div>No history available.</div>
      )}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange('previous')}
          disabled={currentPage === 1}
          className="mr-2 px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default History;
