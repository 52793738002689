import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import resetImage from "../Assets/Images/confirmImg.png";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordReset: React.FC = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const verificationType = searchParams.get("verificationType");
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  useEffect(() => {
    if (!email) {
      toast.error("Email is required for verification.");
      navigate("/");
    }
    if (verificationType === "passwordReset") {
      setShowPasswordFields(true);
    }
  }, [email, navigate, verificationType]);

  const validatePassword = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
  };

  const handleChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (value.length === 1 && index < 3) {
        (
          document.getElementById(`code-${index + 1}`) as HTMLInputElement
        )?.focus();
      }
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    if (
      !isLengthValid ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      toast.error("Password does not meet all requirements.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/password-reset",
        { email, code: verificationCode.join(""), password }
      );

      if (response.data.success) {
        toast.success("Password reset successful.");
        navigate("/reset_password_confirmation");
      } else {
        toast.error("Failed to reset password.");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.error?.message ||
          "An error occurred during password reset."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleResendToken = async () => {
    setResending(true);
    try {
      await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/resend-token",
        { email }
      );
      toast.success("Verification token resent. Please check your email.");
    } catch (error: any) {
      toast.error(
        error.response?.data?.error?.message ||
          "An error occurred while resending the token."
      );
    } finally {
      setResending(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="min-h-screen flex max-w-screen-2xl">
      <div className="flex-1 flex justify-center items-center bg-gray-100 w-full max-w-full">
        <div className="bg-white rounded shadow-md w-full h-full py-7 lg:px-24 px-8">
          <h1 className="logoText text-[#0066FF] mb-4">
            ai<span className="text-black">de</span>ja
          </h1>
          <h2 className="text-3xl font-extrabold text-left mb-2">
            Verification
          </h2>
          <p className="text-left text-[24px] font-[500] my-8">
            Verify your email address
          </p>
          <p className="text-left mb-6 font-[500] text-[14px]">
            We sent you a code to verify your email address
          </p>
          <p className="text-left mb-4">
            Sent to{" "}
            <span className=" text-[14px] font-[400] underline">{email}</span>
          </p>
          <div className="flex justify-left mb-8 space-x-4">
            {verificationCode.map((code, index) => (
              <input
                key={index}
                id={`code-${index}`}
                type="text"
                className="w-16 h-14 lg:w-[100px] lg:h-18  border border-[#0066FF] text-center text-2xl rounded-[12px] mx-1"
                value={code}
                onChange={(e) => handleChange(index, e.target.value)}
                maxLength={1}
              />
            ))}
          </div>

          {showPasswordFields && (
            <div className="">
              <div className="bg-white rounded text-center">
                {/* <h1 className="text-4xl mt-6 h-[55px]" style={{ fontWeight: 950 }}>
                 New Password
                </h1> */}
                <form onSubmit={handlePasswordReset}>
                  <div className="">
                    <div>
                      <div className="flex items-center justify-between mt-2">
                        <label className="block text-[#666666] mb-1 text-left font-normal">
                          New Password
                        </label>
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="text-gray-500 bg-white my-0"
                        >
                          <div className="flex ">
                            <span className="pt-1">
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <span className="mx-2 ">
                              {passwordVisible ? "Hide" : "Show"}
                            </span>
                          </div>
                        </button>
                      </div>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          validatePassword(e.target.value);
                        }}
                      />
                    </div>

                    <div>
                      <div className="flex items-center justify-between mt-6">
                        <label className="block text-[#666666] mb-1 text-left font-normal">
                          Re-enter Password
                        </label>
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="text-gray-500 bg-white my-0"
                        >
                          <div className="flex ">
                            <span className="pt-1">
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <span className="mx-2 ">
                              {passwordVisible ? "Hide" : "Show"}
                            </span>
                          </div>
                        </button>
                      </div>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className="text-gray-500 text-sm mb-4">
                      <div className="md:flex justify-between md:p-2">
                        <div className="flex items-center">
                          <span
                            className={`dot ${
                              isLengthValid ? "bg-green-500" : "bg-gray-300"
                            }`}
                          ></span>
                          <p className="ml-2">Use 8 or more characters</p>
                        </div>
                        <div className="flex items-center">
                          <span
                            className={`dot ${
                              hasUpperCase ? "bg-green-500" : "bg-gray-300"
                            }`}
                          ></span>
                          <p className="ml-2">One Uppercase character</p>
                        </div>
                        <div className="flex items-center">
                          <span
                            className={`dot ${
                              hasLowerCase ? "bg-green-500" : "bg-gray-300"
                            }`}
                          ></span>
                          <p className="ml-2">One Lowercase character</p>
                        </div>
                      </div>
                      <div className="md:flex md:space-x-4 md:p-2">
                        <div className="flex items-center">
                          <span
                            className={`dot ${
                              hasNumber ? "bg-green-500" : "bg-gray-300"
                            }`}
                          ></span>
                          <p className="ml-2">One number</p>
                        </div>
                        <div className="flex items-center md:pl-28">
                          <span
                            className={`dot ${
                              hasSpecialChar ? "bg-green-500" : "bg-gray-300"
                            }`}
                          ></span>
                          <p className="ml-2">One special character</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="flex flex-col lg:flex-row items-center mb-4 space-y-4 lg:space-y-0 lg:space-x-4">
            <button
              onClick={handlePasswordReset}
              className="bg-[#0066FF] text-white py-3 px-8 rounded-full h-14 w-full lg:w-[40%]"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify"}
            </button>
            <p className="text-center flex flex-col lg:flex-row items-center">
              <span className="pb-3 lg:pb-0">I didn’t receive a code</span>
              <button
                onClick={handleResendToken}
                className="text-blue-500 lg:ml-2 underline font-bold"
                disabled={resending}
              >
                {resending ? "Resending..." : "Resend"}
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-200 max-w-md">
        <img
          src={resetImage}
          alt="Email Confirmation"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default PasswordReset;
