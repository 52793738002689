import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfilePhotoUpload from "../components/ProfilePhotoUpload ";
import PasswordSection from "../components/PasswordSection";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import { API_URL } from "../constants";

interface User {
  firstname: string;
  lastname: string;
  email: string;
  photo?: File | null;
}

const getToken = (): string | null => {
  return localStorage.getItem("authToken");
};

const Settings: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isPasswordView, setIsPasswordView] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = getToken();
        if (!token) {
          throw new Error("Token not found");
        }

        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        if (tokenPayload.exp < currentTime) {
          setError("Token has expired. Please log in again.");
          toast.error("Token has expired. Please log in again.");
          setTimeout(() => {
            navigate("/");
          }, 2000);
          return;
        }

        const response = await axios.get(
          `${API_URL}/user/me`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.data && response.data.data && response.data.data.user) {
          setUser(response.data.data.user);
          setEditedUser(response.data.data.user);
        } else {
          setError("User data not found");
          toast.error("User data not found");
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (
            error.response?.data?.error?.message ===
            "Invalid jwt token provided"
          ) {
            setError("Invalid JWT token. Please log in again.");
            toast.error("Invalid JWT token. Please log in again.");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            setError("Error fetching user details");
            toast.error("Error fetching user details");
          }
        } else {
          setError("Unexpected error occurred");
          toast.error("Unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => {
      if (prevUser) {
        return { ...prevUser, [name]: value };
      }
      return prevUser;
    });
  };

  const handlePhotoChange = (newPhoto: File | null) => {
    setEditedUser((prevUser) => {
      if (prevUser) {
        return { ...prevUser, photo: newPhoto };
      }
      return prevUser;
    });
  };

  const hasChanges = () => {
    if (!user || !editedUser) return false;
    return (
      user.firstname !== editedUser.firstname ||
      user.lastname !== editedUser.lastname ||
      user.photo !== editedUser.photo
    );
  };

  const handleSave = async () => {
    if (!editedUser || !hasChanges()) {
      toast.warn("No changes to update.");
      return;
    }

    setIsSaving(true);

    try {
      const token = getToken();
      if (!token) {
        throw new Error("Token not found");
      }

      const formData = new FormData();
      formData.append("firstname", editedUser.firstname);
      formData.append("lastname", editedUser.lastname);
      if (editedUser.photo) {
        formData.append("photo", editedUser.photo);
      }

      console.log("FormData entries:", Array.from(formData.entries()));

      const response = await axios.patch(
        `${API_URL}/user/me/update`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setUser(response.data.data.user);
        toast.success("User details updated successfully!");
        window.location.reload();
        console.log("Update Response:", response.data);
      } else {
        toast.error("Failed to update user details");
      }
    } catch (error: any) {
      console.error("Update error:", error);
      toast.error("Error updating user details");
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color={"#123abc"} loading={loading} size={70} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
    return <div>Error loading user details.</div>;
  }
  const capitalizeFirstLetter = (string:string) => {
    if (!string) return ''; // Handle empty or undefined strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="">
      <h2 className="text-[40px] font-[800] mb-6 text-start">Settings</h2>
      <div className="bg-white p-4 shadow-md max-w-6xl rounded-lg w-full">
        <div className="flex justify-between items-center mb-6 md:p-2 py-4">
          <div className="w-[124px] h-[124px] bg-blue-600 text-white rounded-full flex items-center justify-center text-4xl font-bold">
            {user.photo ? (
              typeof user.photo === "string" ? (
                <img
                  src={user.photo}
                  alt={`${user.firstname} ${user.lastname}`}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <img
                  src={URL.createObjectURL(user.photo)}
                  alt={`${user.firstname} ${user.lastname}`}
                  className="w-full h-full rounded-full object-cover"
                />
              )
            ) : user.firstname && user.lastname ? (
              `${user.firstname.charAt(0)}${user.lastname.charAt(0)}`
            ) : (
              "?"
            )}
          </div>
          <div className="flex">
            <span className="block my-auto">
              <button
                className={`px-3 py-2 h-10 ${
                  !isPasswordView ? "bg-blue-600 text-white" : "bg-[#F8F8F8]"
                }`}
                style={{ borderRadius: "6px 0px 6px 0px" }}
                onClick={() => setIsPasswordView(false)}
              >
                Profile
              </button>
            </span>
            <span className="block my-auto">
              <button
                className={`px-3 py-2 h-10 ${
                  isPasswordView ? "bg-blue-600 text-white" : "bg-[#F8F8F8]"
                }`}
                style={{ borderRadius: "0px 6px 0px 6px" }}
                onClick={() => setIsPasswordView(true)}
              >
                Password
              </button>
            </span>
          </div>
        </div>
        {!isPasswordView ? (
          <div className="max-w-2xl md:mx-10 mx-2">
            <div className="flex mb-4 w-full">
              <div className="flex-1 md:px-2 basis-[50%]">
                <label className="block text-[#666666] mb-1 text-left font-normal">
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                  name="firstname"
                  value={capitalizeFirstLetter(editedUser?.firstname || "")}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex-1 px-2 basis-[50%]">
                <label className="block text-[#666666] mb-1 text-left font-normal">
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                  name="lastname"
                  value={capitalizeFirstLetter(editedUser?.lastname || "")}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-[#666666] mb-1 text-left font-normal">
                Email
              </label>
              <input
                type="email"
                className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                value={user.email}
                readOnly
              />
            </div>
            <ProfilePhotoUpload
              onPhotoChange={handlePhotoChange}
              firstname={user.firstname}
              lastname={user.lastname}
              photo={user.photo}
            />
            <button
              className="w-[256px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[64px] mt-5 mx-auto block"
              onClick={handleSave}
              disabled={isSaving || !hasChanges()}
            >
              {isSaving ? "Saving..." : "Update Changes"}
            </button>
          </div>
        ) : (
          <PasswordSection />
        )}
      </div>
    </div>
  );
};

export default Settings;
