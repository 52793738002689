import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const GOOGLE_CLI_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLI_ID}>
    <BrowserRouter>
      <React.StrictMode>
        <ToastContainer />
        <App />
    </React.StrictMode>
  </BrowserRouter>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
