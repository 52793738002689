import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../Assets/Images/upload.svg";

interface ProfilePhotoUploadProps {
  onPhotoChange: (newPhoto: File | null) => void;
  firstname: string;
  lastname: string;
  photo?: File | null;
}

const ProfilePhotoUpload: React.FC<ProfilePhotoUploadProps> = ({ onPhotoChange, firstname, lastname, photo }) => {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
    onPhotoChange(acceptedFiles[0]);
  }, [onPhotoChange]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 2 * 1024 * 1024, // 2 MB
  });

  const handleDelete = () => {
    setFile(null);
    onPhotoChange(null);
  };

  return (
    <div className="w-full max-w-xl mx-auto my-8 ">
      <div className="flex items-center space-x-4 mb-1 md:ml-[-40px]">
        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt="Profile"
            className="w-16 h-16 rounded-full object-cover"
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-500">
              <div className="w-16 h-16 rounded-full text-white flex items-center justify-center bg-blue-700">
              {photo ? (
              typeof photo === "string" ? (
                <img
                  src={photo}
                  alt={`${firstname} ${lastname}`}
                  className="w-full h-full rounded-full object-cover"
                />
              ) : (
                <img
                  src={URL.createObjectURL(photo)}
                  alt={`${firstname} ${lastname}`}
                  className="w-full h-full rounded-full object-cover"
                />
              )
            ) : firstname && lastname ? (
              `${firstname.charAt(0)}${lastname.charAt(0)}`
            ) : (
              "?"
            )}
              </div>
            </span>
          </div>
        )}
        <div>
          <p className="text-sm text-gray-600">Profile photo</p>
          <p className="text-xs text-gray-400">Max file size is 2mb. Supported files are png and jpeg</p>
        </div>
      </div>

      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 p-6 text-center"
      >
        <input {...getInputProps()} className="w-full" />
        <img src={upload} className="block mx-auto py-1" alt="upload Photo" />
        <p className="text-gray-500">Drag and drop file here</p>
        <p className="text-gray-400">or</p>
        <p className="text-blue-500 cursor-pointer">Click to browse files</p>
      </div>

      {file && (
        <button
          onClick={handleDelete}
          className="text-red-500 mt-3 flex justify-start"
        >
          Delete
        </button>
      )}
    </div>
  );
};

export default ProfilePhotoUpload;
