import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectedFurnitureModal from "./SelectedFurnitures";
import FilterModal from "./FilterModel";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { API_URL } from "../constants";
import axios from "axios";
import leftIcon from '../Assets/Images/left.svg';
import rightIcon from '../Assets/Images/right.svg';

interface Furniture {
  _id: string;
  details: string;
  imageUrl: string[];
  title: string;
  categories: string[];
  style: string;
}

interface FilterOptions {
  categories: string[];
  styles: string[];
}


const FilteredResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate: any = useNavigate();
  const [selectedFurnitureIds, setSelectedFurnitureIds] = useState<string[]>([]);
  const [selectedFurnitures, setSelectedFurnitures] = useState<Furniture[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Furniture[]>([]);
  const [allFurnitures, setAllFurnitures] = useState<Furniture[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    categories: [],
    styles: [],
  });
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedStyle, setSelectedStyle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const _filteredData = navigate.state?.filteredData;
  useEffect(() => {
    const fetchFilterOptions = async () => {
      const token = localStorage.getItem("authToken") || "";
      try {
        const response = await fetch(
          `${API_URL}/user/filter`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const uniqueCategories: string[] = data.data.uniqueCategories || [];
        const uniqueStyles: string[] = data.data.uniqueStyles || [];
        console.log(uniqueCategories);

        // const uniqueCategories = new Set<string>();
        // const uniqueStyles = new Set<string>();

        // furnitures.forEach((furniture: Furniture) => {
        //   uniqueStyles.add(furniture.style);
        //   furniture.categories.forEach((category: string) =>
        //     uniqueCategories.add(category.trim())
        //   );
        // });

        setFilterOptions({
          categories: Array.from(uniqueCategories),
          styles: Array.from(uniqueStyles),
        });
      } catch (error) {
        console.error("Error fetching filter options:", error);
      }
    };

    fetchFilterOptions();
  }, []);



  useEffect(() => {

    const fetchFurnitures = async () => {
      const token = localStorage.getItem("authToken") || "";
      let items = localStorage.getItem("items") || "";
      let preferredStyle = localStorage.getItem("preferredStyle") || "";
      let preferredColor = localStorage.getItem("preferredColor") || "";
      items = JSON.parse(items)
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/user/furnitures`, // Adjust limit as needed
          {

            params: {
              limit: 30,
              count: 1,
              items,
              style: preferredStyle,
              color: preferredColor
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );


        setAllFurnitures(response.data.data.furnitures || []);
        setFilteredData(response.data.data.furnitures || []);
        console.log(response.data.data.furnitures)
        setSelectedFurnitureIds([])
        setSelectedFurnitures([])
      } catch (error) {
        setError("");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFurnitures();
    // console.log(_filteredData)
    //       setAllFurnitures( _filteredData.furnitures || []);
    //     setFilteredData( _filteredData.furnitures || []);

  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(allFurnitures);
    } else {
      setFilteredData(
        allFurnitures.filter((furniture) =>
          furniture.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
    setCurrentPage(1);
  }, [searchQuery, allFurnitures]);


  useEffect(() => {
    const storedSelectedIds = localStorage.getItem("selectedFurnitureIds");
    if (storedSelectedIds) {
      setSelectedFurnitureIds(JSON.parse(storedSelectedIds));
    }
  }, []);

  useEffect(() => {
    const storedSelectedFurnitures = localStorage.getItem("selectedFurnitures");
    if (storedSelectedFurnitures) {
      setSelectedFurnitures(JSON.parse(storedSelectedFurnitures));
    }
  }, []);

  useEffect(() => {
    if (selectedFurnitureIds.length > 0) {
      localStorage.setItem(
        "selectedFurnitureIds",
        JSON.stringify(selectedFurnitureIds)
      );
    } else {
      localStorage.removeItem("selectedFurnitureIds");
    }
    console.log("Saving Selected IDs to Local Storage:", selectedFurnitureIds);
  }, [selectedFurnitureIds]);

  useEffect(() => {
    if (selectedFurnitures.length > 0) {
      localStorage.setItem(
        "selectedFurnitures",
        JSON.stringify(selectedFurnitures)
      );
    } else {
      localStorage.removeItem("selectedFurnitures");
    }
    console.log("Saving Selected Furnitures to Local Storage:", selectedFurnitures);
  }, [selectedFurnitures]);

  const handleSelect = (_id: string) => {
    console.log("_id")
    console.log(_id)

    const findFurnitureById = (_id: string, allFurnitures: any): Furniture | null => {
      // Iterate through each category
      for (const category in allFurnitures) {
        // Type assertion to ensure we're working with an array
        const categoryArray = allFurnitures[category] as any[];
        console.log("categoryArray")
        console.log(categoryArray)
        const keys: any = Object.keys(categoryArray);
        // Find the furniture item in the current category
        const selectedFurniture = categoryArray[keys[0]].find((f: any) => f._id === _id);
        if (selectedFurniture) {
          return selectedFurniture;
        }
      }
      return null; // If no furniture is found with the given _id
    };

    const selectedFurniture = findFurnitureById(_id, allFurnitures);

    if (!selectedFurniture) {
      console.error(`Furniture with id ${_id} not found.`);
      return;
    }

    console.log("Selected Furniture:", selectedFurniture);

    setSelectedFurnitureIds((prevSelected) => {
      const newSelected = prevSelected.includes(_id)
        ? prevSelected.filter((selectedId) => selectedId !== _id)
        : [...prevSelected, _id];

      console.log("Updated Selected IDs:", newSelected);

      let updatedSelectedFurnitures = [...selectedFurnitures];
      if (newSelected.includes(_id)) {
        if (!updatedSelectedFurnitures.some((item) => item && item._id === _id)) {
          updatedSelectedFurnitures.push(selectedFurniture);
        }
      } else {
        updatedSelectedFurnitures = updatedSelectedFurnitures.filter(
          (item) => item && item._id !== _id
        );
      }

      console.log("Updated Selected Furnitures:", updatedSelectedFurnitures);

      setSelectedFurnitures(updatedSelectedFurnitures);

      return newSelected;
    });
  };

  const getFirstName = (): string | null => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      const firstname = user;

      if (typeof firstname === "string" && firstname.length > 0) {
        return firstname.charAt(0).toUpperCase() + firstname.slice(1);
      } else {
        return null;
      }
    }
    return null;
  };

  const firstName = getFirstName() || "User";

  const applyFilters = async (category: string, style: string) => {
    const token = localStorage.getItem("authToken") || "";
    setLoading(true);
    try {
      let url = `${API_URL}/user/furnitures?limit=30`; // Ensure to fetch a large dataset

      if (category) {
        console.log("category: ", category)
        url += `&category=${encodeURIComponent(category)}`;
      }
      if (style) {
        url += `&style=${style}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const filteredFurnitures = data.data.furnitures || [];
      setFilteredData(filteredFurnitures);

    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
      setIsFilterModalOpen(false);
      setCurrentPage(1); // Reset to the first page after filtering
    }
  };

  const handleFilterClick = () => {
    setIsFilterModalOpen(true);
  };

  const resetFilters = () => {
    setSelectedCategory("");
    setSelectedStyle("");
    setSearchQuery("");
    setFilteredData(allFurnitures);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    // Smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  let scrollContainer: HTMLDivElement | null = null;
  const scrollContainers = useRef<(HTMLDivElement | null)[]>([]);
  const scrollLeft = (scrollContainer: any) => {
    if (scrollContainer) {
      scrollContainer.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = (scrollContainer: any) => {
    if (scrollContainer) {
      scrollContainer.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };



  return (
    <div className="flex-1 h-full overflow-y-auto">
      <div className="min-h-screen flex flex-col items-center py-7 w-full justify-center">
        {loading && <ClipLoader color={"#123abc"} loading={loading} size={70} />}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <>
            <div className="text-start w-full max-w-screen-lg px-2">
              {/* Header */}
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-4xl font-bold mb-2">Hello {firstName},</h2>
                </div>
                {/* <div className="mb-3 md:mb-0 md:mx-2">
                  <button
                    className="bg-blue-500 text-white p-4 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150"
                    onClick={() => setIsFilterModalOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h8m-8 6h16"
                      />
                    </svg>
                  </button>
                </div> */}
              </div>
              <p className="text-lg mb-6">What furniture would you like to see today?</p>
              {/* Search and View Selected */}
              <div className="flex flex-col lg:flex-row justify-between items-center mb-6 space-y-4 lg:space-y-0">
                <div className="relative w-full lg:w-[75%]">
                  <input
                    type="text"
                    placeholder="Search for names"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full p-4 rounded-[16px] outline-blue-400 border border-gray-300 shadow-sm bg-[#F3F4F9]"
                  />
                </div>
                <div className="w-full lg:w-[20%] flex justify-end lg:justify-start lg:items-center lg:mr-2">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex items-center bg-blue-500 text-white px-4 py-4 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150 w-full lg:w-auto text-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 7V5a2 2 0 012-2h2M17 3h2a2 2 0 012 2v2M3 17v2a2 2 0 002 2h2m10 0h2a2 2 0 002-2v-2m4-10h-4m-4 0H7m-4 0H3"
                      />
                    </svg>
                    <span className="ml-2 whitespace-nowrap">
                      View Selected ({selectedFurnitureIds.length})
                    </span>
                  </button>
                </div>
              </div>

              {selectedCategory || selectedStyle ? (
                <div className="flex justify-end mb-4">
                  <button
                    onClick={resetFilters}
                    className="bg-green-500 text-white px-4 py-2 rounded-full shadow hover:bg-green-600 transition ease-in-out duration-150"
                  >
                    Go to All Furnitures
                  </button>
                </div>
              ) : null}

              {/* Furniture Display */}
              <div className="space-y-6">
                {allFurnitures.map((category: any, index) => {

                  console.log("index", index)

                  return (
                    <div key={Object.keys(category)[0]} className="relative">
                      {/* Category name */}
                      <h2 className="text-lg font-bold mb-2">
                        {Object.keys(category)[0]} ({(Object.values(category)[0] as any)?.length})
                      </h2>

                      {/* Furniture container with buttons */}
                      <div className="flex items-center">
                        {/* Left Scroll Button */}
                        {/* Left Scroll Button */}
                        <button
                          className="bg-gray-200 hover:bg-blue-300 p-2 rounded-full focus:outline-none flex items-center justify-center absolute left-0 top-1/2 transform -translate-y-1/2 z-10 left-scroll-button"
                          onClick={() => scrollLeft(scrollContainers.current[index])}
                        >
                          <img src={leftIcon} alt="Left Scroll Icon" className="w-[32px] h-[32px]" />
                        </button>


                        {/* Furniture items container */}
                        <div className="flex-grow overflow-hidden relative pl-10 pr-10">
                          {/* Furniture items */}
                          <div
                            className="flex space-x-4 scroll-smooth no-scrollbar scroll-hidden"
                            ref={(el) => {
                              scrollContainers.current[index] = el;
                            }}
                            style={{ minWidth: "100%", overflowX: "scroll" }}
                          >
                            {category[Object.keys(category)[0]].map((furniture: any, furnitureIndex: number) => (
                              <div
                                key={furnitureIndex}
                                className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between min-w-[250px]"
                              >
                                {/* Furniture item content */}
                                <img
                                  src={furniture.imageUrl?.[0] || "default-image-url.jpg"}
                                  alt={furniture.title}
                                  className="object-contain object-top mb-3"
                                />
                                <div className="flex-grow">
                                  <h3 className="text-[14px] font-bold mb-2">{furniture.title}</h3>
                                  <p className="text-gray-600 mb-4 text-[12px] line-clamp-3">
                                    {furniture.details}
                                  </p>
                                </div>
                                <button
                                  onClick={() => handleSelect(furniture._id)}
                                  className={`py-2 md:py-1 w-full border border-blue-500 text-blue-500 rounded-[16px] transition ease-in-out duration-150 ${selectedFurnitureIds.includes(furniture._id)
                                    ? "bg-blue-100"
                                    : "hover:bg-blue-200 hover:text-blue-700"
                                    }`}
                                >
                                  {selectedFurnitureIds.includes(furniture._id) ? "Selected" : "Select"}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Right Scroll Button */}
                        <button
                          className="bg-gray-200 hover:bg-blue-300 p-2 rounded-full focus:outline-none flex items-center justify-center absolute right-0 top-1/2 transform -translate-y-1/2"
                          onClick={() => scrollRight(scrollContainers.current[index])}
                        >
                          <img src={rightIcon} alt="Right Scroll Icon" className="w-[32px] h-[32px]" />
                        </button>
                      </div>
                    </div>

                  );
                })}
              </div>


              {/* Pagination Controls */}
              {/* <div className="mt-6 flex justify-center space-x-2">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-4 py-2 rounded-lg ${currentPage === 1
                    ? "bg-gray-300 text-gray-800 opacity-50 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                    }`}
                >
                  Previous
                </button>
                <span className="flex items-center px-4 py-2 text-gray-800">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-4 py-2 rounded-lg ${currentPage === totalPages
                    ? "bg-gray-300 text-gray-800 opacity-50 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                    }`}
                >
                  Next
                </button>
              </div> */}
            </div>
            <SelectedFurnitureModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              selectedFurnitures={selectedFurnitures}
              onSelect={handleSelect}
              selectedFurnitureIds={selectedFurnitureIds}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FilteredResultsPage;
