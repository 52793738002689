import io from 'socket.io-client';
import { API_URL } from './environment';
import { useNavigate } from 'react-router-dom';

const socket = io(`${API_URL}/chatbot2`, {
  transports: ['websocket', 'polling'],
  auth: {
    authorization: localStorage.getItem('authToken'),
  },
});
socket.on('connect_error', (err) => {
  // const navigate: any = useNavigate();
  console.error('Socket connection error:', err);
  // console.log("err.message");
  // console.log(err.message);
  // if(err.message=="user not authenticated") {
  //   // navigate("/login");
  // }
});

socket.on('connect', () => {
  console.log('Socket connected successfully');
});

socket.on('disconnect', (reason) => {
  console.warn('Socket disconnected:', reason);
});

socket.on('reconnect_attempt', () => {
  console.log('Reconnecting...');
});

export default socket;
