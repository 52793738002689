import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PasswordSection: React.FC = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "oldPassword") setOldPassword(value);
    if (name === "newPassword") {
      setNewPassword(value);
      validatePassword(value);
    }
    if (name === "reEnterPassword") setReEnterPassword(value);
  };

  const validatePassword = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^A-Za-z0-9]/.test(password));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordSubmit = async () => {
    if (
      !isLengthValid ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      toast.error("Password does not meet all requirements.");
      return;
    }

    if (!oldPassword || !newPassword || !reEnterPassword) {
      toast.error("Please fill in all fields");
      return;
    }

    if (newPassword !== reEnterPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const requestData = {
      currentPassword: oldPassword,
      password: newPassword,
    };

    try {
      setIsLoading(true);

      // Get the token from localStorage (or wherever you have stored it)
      const token = localStorage.getItem("authToken");

      const response = await axios.patch(
        "https://aideja-a369a7fe00de.herokuapp.com/user/me/password/update",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Include the token in the Authorization header
          },
        }
      );

      window.location.reload(); 
      toast.success(response.data.message);
    } catch (err: any) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.error?.message || "An error occurred");
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="password-section max-w-2xl md:mx-10 mx-2">
      <div className="mb-6">
        <div className="flex items-center justify-between mt-2">
          <label className="block text-[#666666] mb-1 text-left font-normal">
            Old Password
          </label>
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="text-gray-500 bg-white my-0"
          >
            <div className="flex ">
              <span className="pt-1">
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
              <span className="mx-2 ">
                {passwordVisible ? "Hide" : "Show"}
              </span>
            </div>
          </button>
        </div>
        <input
          type={passwordVisible ? "text" : "password"}
          className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
          name="oldPassword"
          value={oldPassword}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="mb-6">
        <div className="flex items-center justify-between mt-2">
          <label className="block text-[#666666] mb-1 text-left font-normal">
            New Password
          </label>
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="text-gray-500 bg-white my-0"
          >
            <div className="flex ">
              <span className="pt-1">
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
              <span className="mx-2 ">{passwordVisible ? "Hide" : "Show"}</span>
            </div>
          </button>
        </div>
        <input
          type={passwordVisible ? "text" : "password"}
          className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
          name="newPassword"
          value={newPassword}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="mb-6">
        <div className="flex items-center justify-between mt-2">
          <label className="block text-[#666666] mb-1 text-left font-normal">
            Re-enter Password
          </label>
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="text-gray-500 bg-white my-0"
          >
            <div className="flex ">
              <span className="pt-1">
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
              <span className="mx-2 ">{passwordVisible ? "Hide" : "Show"}</span>
            </div>
          </button>
        </div>
        <input
          type={passwordVisible ? "text" : "password"}
          className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
          name="reEnterPassword"
          value={reEnterPassword}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="text-gray-500 text-sm mb-4">
        <div className="md:flex justify-between md:p-2 pt-2">
          <div className="flex items-center">
            <span
              className={`dot ${
                isLengthValid ? "bg-green-500" : "bg-gray-300"
              }`}
            ></span>
            <p className="ml-2">Use 8 or more characters</p>
          </div>
          <div className="flex items-center">
            <span
              className={`dot ${hasUpperCase ? "bg-green-500" : "bg-gray-300"}`}
            ></span>
            <p className="ml-2">One Uppercase character</p>
          </div>
          <div className="flex items-center">
            <span
              className={`dot ${hasLowerCase ? "bg-green-500" : "bg-gray-300"}`}
            ></span>
            <p className="ml-2">One Lowercase character</p>
          </div>
        </div>
        <div className="md:flex md:space-x-4 md:p-2">
          <div className="flex items-center">
            <span
              className={`dot ${hasNumber ? "bg-green-500" : "bg-gray-300"}`}
            ></span>
            <p className="ml-2">One number</p>
          </div>
          <div className="flex items-center md:pl-28">
            <span
              className={`dot ${
                hasSpecialChar ? "bg-green-500" : "bg-gray-300"
              }`}
            ></span>
            <p className="ml-2">One special character</p>
          </div>
        </div>
      </div>
      <button
        className={`w-[256px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[64px] mt-5 mx-auto block ${
          isLoading
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[#0066FF] hover:bg-[#0056b3]"
        }`}
        onClick={handlePasswordSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Updating..." : "Change Password"}
      </button>
    </div>
  );
};

export default PasswordSection;
