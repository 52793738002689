import React, { useState, useEffect } from "react";
import socket from "../constants/environment/socket";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";

interface RoomParams {
  roomType: string;
  roomSize: string;
  preferredColor: string;
  preferredStyle: string;
  furnitureIDs: string[];
}

interface RoomData {
  generatedImage: string;
  itemTable: any;
  fetchUrl: string;
  name: string;
}

interface Furniture {
  _id: string;
  details: string;
  imageUrl: string[];
  title: string;
}

const GenerateVisualization: React.FC = () => {
  const [roomParams, setRoomParams] = useState<RoomParams | null>(null);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null);
  const [itemTable, setItemTable] = useState<any>(null);
  const [roomName, setRoomName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [fetchUrl, setFetchUrl] = useState<string | null>(null);
  const [selectedFurnitures, setSelectedFurnitures] = useState<Furniture[]>([]);

  useEffect(() => {
    const params = localStorage.getItem("roomParams");
    let parsedParams: RoomParams | null = null;
    if (params) {
      parsedParams = JSON.parse(params);
      console.log("Retrieved roomParams from localStorage:", parsedParams);
      setRoomParams(parsedParams);
    }
  
    const selectedFurnituresData = JSON.parse(
      localStorage.getItem("selectedFurnitures") || "[]"
    ) as Furniture[];
    console.log(
      "Retrieved selectedFurnitures from localStorage:",
      selectedFurnituresData
    );
    if (selectedFurnituresData.length > 0) {
      const uniqueFurnitures = Array.from(
        new Set(selectedFurnituresData.map((furniture) => furniture._id))
      ).map((id) => selectedFurnituresData.find((furniture) => furniture._id === id)!);
      setSelectedFurnitures(uniqueFurnitures);
    }
  
    const storedGeneratedImage = localStorage.getItem("generatedImage");
    if (storedGeneratedImage) {
      setGeneratedImage(storedGeneratedImage);
    }
  
    const storedItemTable = localStorage.getItem("itemTable");
    if (storedItemTable) {
      setItemTable(JSON.parse(storedItemTable));
    }
  
    const storedRoomName = localStorage.getItem("roomName");
    if (storedRoomName) {
      setRoomName(storedRoomName);
    }
  
    const storedFetchUrl = localStorage.getItem("fetchUrl");
    if (storedFetchUrl) {
      setFetchUrl(storedFetchUrl);
    }
  
    if (parsedParams && storedGeneratedImage && storedItemTable && storedRoomName && storedFetchUrl) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (roomParams && loading) {
      console.log("Emitting visualize-room with params:", roomParams);
      // socket.emit("visualize-room", roomParams);
  
      // const timeout = setTimeout(() => {
      //   if (loading) {
      //     setLoading(false);
      //     setError("Room visualization generation timed out. Please try again.");
      //   }
      // }, 300000);
  
      const handleMessageReceived = (data: any) => {
        // clearTimeout(timeout);
        setLoading(false);
        console.log("Received message:received event with data:", data);
  
        if (data.message) {
          const roomData: RoomData = data.message;
          if (roomData.generatedImage) {
            setGeneratedImage(roomData.generatedImage);
          } else {
            setError("Generated image not found in response.");
            console.error("Generated image not found in response:", data);
          }
          if (roomData.itemTable) {
            setItemTable(roomData.itemTable);
          }
          if (roomData.fetchUrl) {
            setFetchUrl(roomData.fetchUrl);
          }
          if (roomData.name) {
            setRoomName(roomData.name);
          }
        } else {
          setError("Response does not contain the expected data.");
          console.error("Response does not contain the expected data:", data);
        }
      };
  
      const handleError = (error: any) => {
        // clearTimeout(timeout);
        setLoading(false);
        setError("Error received from socket: " + error);
        console.error("Error received from socket:", error);
      };
  
      socket.on("message:received", handleMessageReceived);
      socket.on("error", handleError);
  
      return () => {
        console.log("Cleaning up message:received and error listeners");
        socket.off("message:received", handleMessageReceived);
        socket.off("error", handleError);
        // clearTimeout(timeout);
      };
    }
  }, [roomParams, loading]); // Only emit when roomParams is set and loading is true
  

  useEffect(() => {
    if (fetchUrl) {
      const fetchData = async () => {
        try {
          const response = await fetch(fetchUrl);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setItemTable(data);
        } catch (error) {
          setError("Error fetching additional data: " + error);
          console.error("Error fetching additional data:", error);
        }
      };

      fetchData();
    }
  }, [fetchUrl]);

  useEffect(() => {
    if (generatedImage) {
      localStorage.setItem("generatedImage", generatedImage);
    }
  }, [generatedImage]);

  useEffect(() => {
    if (itemTable) {
      localStorage.setItem("itemTable", JSON.stringify(itemTable));
    }
  }, [itemTable]);

  useEffect(() => {
    if (roomName) {
      localStorage.setItem("roomName", roomName);
    }
  }, [roomName]);

  useEffect(() => {
    if (fetchUrl) {
      localStorage.setItem("fetchUrl", fetchUrl);
    }
  }, [fetchUrl]);

  const handleDownload = () => {
    if (generatedImage) {
      saveAs(generatedImage, "room-visualization.png");
    }
  };
  const handleRegenrate = () => {
    socket.emit("visualize-room", roomParams);
    setLoading(true);
  };

  if (loading) {
    return <div>Generating room visualization, please wait...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center py-4 w-full h-full bg-[#F8F8F8]">
      <div className="text-start w-full max-w-screen-lg px-4 sm:px-6 lg:px-3">
        <h1 className="text-2xl sm:text-3xl font-[800] mb-6">
          {roomName || "Room Visualization"}
        </h1>
      </div>
      {generatedImage ? (
       <img
       src={generatedImage}
       alt="Generated Room"
       className="w-full rounded-lg mb-4 object-cover h-64 md:h-96 lg:h-[600px] xl:h-[800px]"
     />
     
      ) : (
        <p className="text-center">Image is not available.</p>
      )}
      <div className="w-full max-w-screen-lg p-4 rounded-lg shadow-lg">
        <div className="flex flex-wrap justify-start space-x-3 mb-4 space-y-2 lg:space-y-1">
          {selectedFurnitures.map((furniture) => (
            <img
              key={furniture._id}
              src={furniture.imageUrl[0] || "default-image-url"}
              alt={furniture.title}
              className="w-16 h-16 sm:w-24 sm:h-24 object-cover rounded-lg"
            />
          ))}
          <div className="w-16 h-16 sm:w-20 sm:h-20 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center text-gray-500 cursor-pointer lg:mt-2">
            <Link to={"/dashboard"}>
              + Add
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap justify-center space-x-4 my-3 lg:mt-6">
          <Link to={"/dashboard"}>
            <button className="bg-blue-500 text-white px-4 py-4 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150 lg:w-[256px] lg:py-4">
              Filter Page
            </button>
          </Link>
          <button
            onClick={handleDownload}
            className="bg-blue-500 text-white px-4 py-3 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150 lg:w-[256px] lg:py-4"
          >
            Download Image
          </button>
          <button
            onClick={handleRegenrate}
            className="bg-blue-500 text-white px-4 py-3 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150 lg:w-[256px] lg:py-4"
          >
            Regenerate Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateVisualization;
