import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import resetImage from "../Assets/Images/comfirm2.png";
import { toast } from 'react-toastify';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://aideja-a369a7fe00de.herokuapp.com/auth/password-reset-request', {
        email,
      });

      const { token } = response.data;
      toast.success('Password reset link sent to your email.');
      setIsLoading(false);
      navigate(`/password-reset?email=${email}&verificationType=passwordReset`);
    } catch (error: any) {
      const errorMessage = error.response?.data?.error?.message || 'An error occurred';
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex max-w-screen-2xl">
      <div className="flex-1 flex justify-center items-center bg-gray-100 w-full max-w-full">
        <div className="bg-white rounded shadow-md w-full h-full py-7 lg:px-24 px-8">
          <h1 className="logoText text-[#0066FF] mb-4">
            ai<span className="text-black">de</span>ja
          </h1>
          <h2 className="text-3xl font-extrabold text-left mb-2">
            Forgot Password
          </h2>
          <p className="text-left mb-4 font-[400]">
            Enter your email so we can send a password reset link to your email
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-[#666666] mb-1 text-left font-normal">
                Email
              </label>
              <input
                type="email"
                className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="flex justify-start my-2">
              <button
                type="submit"
                className="w-[256px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[64px]"
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send Email'}
              </button>
            </div>
          </form>
          <p className="text-left mt-4 font-[400]">
            Already have an account? <Link to="/" className="text-blue-500">Log in</Link>
          </p>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-200 max-w-md">
        <img
          src={resetImage}
          alt="Forgot Password"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
