import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';
import socket from '../constants/environment/socket';
// import socket from '../constants/environment/socket';

interface Furniture {
  _id: string;
  details: string;
  imageUrl: string[];
  title: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFurnitures: Furniture[];
  onSelect: (id: string) => void;
  selectedFurnitureIds: string[];
}

const SelectedFurnitureModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  selectedFurnitures,
  onSelect,
  selectedFurnitureIds,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const handleGenerateRoom = () => {
    const roomParams = JSON.parse(localStorage.getItem('filterData') || '{}');
    roomParams.furnitureIDs = Array.from(new Set(selectedFurnitureIds));
    localStorage.setItem('roomParams', JSON.stringify(roomParams));
    socket.emit('visualize-room', roomParams);
    navigate('/dashboard/visualize');
  };

  // Pagination logic
  const totalItems = selectedFurnitures.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedFurnitures = selectedFurnitures.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSelect = (furniture: Furniture) => {
    let storedSelectedFurnitures = JSON.parse(localStorage.getItem('selectedFurnitures') || '[]');

    if (storedSelectedFurnitures.some((item: Furniture) => item._id === furniture._id)) {
      storedSelectedFurnitures = storedSelectedFurnitures.filter((item: Furniture) => item._id !== furniture._id);
    } else {
      storedSelectedFurnitures.push(furniture);
    }

    const uniqueFurnitures = Array.from(new Set(storedSelectedFurnitures.map((item: Furniture) => item._id)))
      .map(_id => storedSelectedFurnitures.find((item: Furniture) => item._id === _id));
    localStorage.setItem('selectedFurnitures', JSON.stringify(uniqueFurnitures));
    onSelect(furniture._id);

    // Sync selected furnitures with the server
    syncSelectedFurnitures(uniqueFurnitures);
  };

  const syncSelectedFurnitures = async (furnitures: Furniture[]) => {
    const token = localStorage.getItem('authToken'); // Ensure you have stored the token

    try {
      const response = await axios.patch(
        `${API_URL}/user/sync`,
        { selectedFurnitures: furnitures },
        {
          headers: {
            Authorization: token, // Include the token in the headers if required
            'Content-Type': 'application/json', // Ensure the content type is correct
          },
        }
      );
      console.log("Sync response:", response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error syncing selected furnitures:", error.response?.data || error.message);
      } else {
        console.error("Unexpected error:", error);
      }
      // Handle error appropriately, e.g., display a notification
    }
  };

  useEffect(() => {
    console.log("selectedFurnitureIds")
    console.log(selectedFurnitureIds)
    console.log(" selectedFurnitures")
    console.log(selectedFurnitures)
    // Sync selected furnitures on component mount
    const storedSelectedFurnitures = JSON.parse(localStorage.getItem('selectedFurnitures') || '[]');
    syncSelectedFurnitures(storedSelectedFurnitures);
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center py-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white px-4 lg:px-10 text-left align-middle shadow-xl transition-all py-12">
                <Dialog.Title
                  as="h3"
                  className="text-[40px] font-[800] leading-6 text-gray-900"
                >
                  Selections
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-[20px] text-[#000000] font-[400] my-3">
                    Here are your selected furnitures
                  </p>
                </div>

                <div className="mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                    {paginatedFurnitures.map((furniture) => (
                      <div
                        key={furniture._id}
                        className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between"
                      >
                        <img
                          src={furniture.imageUrl[0]}
                          alt={furniture.title}
                          className="h-[165px] object-cover w-full object-top rounded-lg mb-3 md:w-full md:object-cover"
                        />
                        <div>
                          <h3 className="text-[14px] font-bold mb-2">
                            {furniture.title}
                          </h3>
                          <p className="text-gray-600 mb-2 text-[12px] line-clamp-2">
                            {furniture.details}
                          </p>
                        </div>
                        <button
                          onClick={() => handleSelect(furniture)}
                          className={`py-2 md:py-1 w-full border border-blue-500 text-blue-500 rounded-[16px] transition ease-in-out duration-150 ${selectedFurnitureIds.includes(furniture._id)
                              ? 'bg-blue-100'
                              : 'hover:bg-blue-200 hover:text-blue-700'
                            }`}
                        >
                          {selectedFurnitureIds.includes(furniture._id)
                            ? 'Unselect'
                            : 'Select'}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-6 flex justify-center space-x-2">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded-lg ${currentPage === 1
                        ? 'bg-gray-300 text-gray-800 opacity-50 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                      }`}
                  >
                    Previous
                  </button>
                  <span className="flex items-center px-4 py-2 text-gray-800">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-lg ${currentPage === totalPages
                        ? 'bg-gray-300 text-gray-800 opacity-50 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                      }`}
                  >
                    Next
                  </button>
                </div>

                <div className="mt-4 flex md:justify-around justify-between">
                  <button
                    type="button"
                    onClick={handleGenerateRoom}
                    className="md:w-[256px] w-[156px] sm:w-[180px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[60px] mt-5 mr-2"
                  >
                    Generate Room
                  </button>
                  <button
                    type="button"
                    className="md:w-[256px] w-[126px] sm:w-[180px] bg-red-500 text-white py-2 rounded-[32px] hover:bg-red-600 text-center h-[60px] mt-5 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SelectedFurnitureModal;
