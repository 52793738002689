import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { API_URL } from "../constants";
import { saveAs } from "file-saver";

interface Furniture {
  _id: string;
  title: string;
  imageUrl: string[];
}

interface HistoryEntry {
  _id: string;
  name: string;
  generatedImage: string;
  furnitures: Furniture[];
}

const HistoryDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [entry, setEntry] = useState<HistoryEntry | null>(null);
  const [furnitures, setFurnitures] = useState<Furniture[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null)

  useEffect(() => {
    const fetchEntry = async () => {
      const token = localStorage.getItem("authToken");
      if (!id || !token) {
        setError("Invalid ID or missing authentication token.");
        setLoading(false);
        return;
      }

      try {
        const entryResponse = await axios.get(
          `${API_URL}/user/project/${id}`,
          {
            headers: { Authorization: token },
          }
        );

        const entryData: HistoryEntry = entryResponse.data.data;
        if (!entryData) {
          throw new Error("Invalid entry data structure.");
        }

        setEntry(entryData);
        setFurnitures(entryData.furnitures);
      } catch (error) {
        console.error("Error fetching entry or furniture details:", error);
        setError(`An error occurred: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEntry();
  }, [id]);

  const getFurnitureImageUrl = (furnitureId: string): string => {
    const furniture = furnitures.find((f) => f._id === furnitureId);
    const image = furniture?.imageUrl[0] || "https://via.placeholder.com/150"
    // setGeneratedImage(image)
    return image;
  };

  if (loading) return (
    <div className="flex justify-center items-center w-full h-full min-h-screen bg-[#F8F8F8]">
      <ClipLoader color="#123abc" loading={loading} size={70} />
    </div>
  );
  if (error) return <div className="text-red-500">{error}</div>;
  if (!entry) return <div>Entry not found.</div>;
  const handleDownload = () => {
    if (entry.generatedImage) {
      saveAs(entry.generatedImage, "room-visualization.png");
    }
  };
  return (
    <div className="min-h-screen flex flex-col items-center py-4 w-full h-full bg-[#F8F8F8]">
      <div className="text-start w-full max-w-screen-lg px-4 sm:px-6 lg:px-3">
        <h1 className="text-2xl sm:text-3xl font-[800] mb-6">
          {entry.name || "Room Visualization"}
        </h1>
      </div>
      <img
        src={entry.generatedImage}
        alt="Generated Room"
        className="w-full rounded-lg mb-4 object-cover lg:h-screen h-auto"
      />
      <div className="w-full max-w-screen-lg p-4 rounded-lg shadow-lg">
        <div className="mb-2 lg:flex align-middle">
          <div className="flex flex-wrap mt-1">
            {furnitures.map((furniture) => (
              <span
                key={furniture._id}
                className="flex flex-wrap justify-start space-x-5 mb-4 space-y-2 lg:space-y-1"
              >
                <img
                  src={getFurnitureImageUrl(furniture._id)}
                  alt={`Furniture ${furniture._id}`}
                  className="w-16 h-16 sm:w-24 sm:h-24 object-cover rounded-lg"
                />
              </span>
            ))}
          </div>
          <div className="flex flex-wrap justify-center space-x-4 my-3 mx-6 lg:mt-6">

            <button
              onClick={handleDownload}
              className="bg-blue-500 text-white px-4 py-3 rounded-full shadow hover:bg-blue-600 transition ease-in-out duration-150 lg:w-[256px] lg:py-4"
            >
              Download Image
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryDetail;
