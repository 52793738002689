import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ConfirmationImage from "../Assets/Images/LoginImg.png";
import { toast } from "react-toastify";

const EmailConfirmation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const verificationType = searchParams.get("verificationType");
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState<string[]>(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);

  useEffect(() => {
    if (!email) {
      toast.error("Email is required for verification.");
      navigate("/");
    }
  }, [email, navigate]);

  const handleChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (value.length === 1 && index < 3) {
        (document.getElementById(`code-${index + 1}`) as HTMLInputElement)?.focus();
      }
    }
  };

  const handleVerify = async () => {
    if (verificationCode.some(code => code === "")) {
      toast.error("Please enter the complete verification code.");
      return;
    }

    setLoading(true);
    const enteredCode = verificationCode.join("");

    try {
      const verifyResponse = await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/verify",
        { email, code: enteredCode }
      );

      if (verifyResponse.data.success) {
        toast.success("Verification successful.");
        if (verificationType === "passwordReset") {
          navigate(`/password-reset?email=${encodeURIComponent(email || '')}&code=${enteredCode}`);
        } else {
          navigate("/");
        }
      } else {
        toast.error("Verification code does not match.");
      }
    } catch (error: any) {
      toast.error(error.response?.data?.error?.message || "An error occurred during verification.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendToken = async () => {
    setResending(true);
    try {
      await axios.post(
        "https://aideja-a369a7fe00de.herokuapp.com/auth/resend-token",
        { email }
      );
      toast.success("Verification token resent. Please check your email.");
    } catch (error: any) {
      toast.error(error.response?.data?.error?.message || "An error occurred while resending the token.");
    } finally {
      setResending(false);
    }
  };

  return (
    <div className="min-h-screen flex max-w-screen-2xl">
      <div className="flex-1 flex justify-center items-center bg-gray-100 w-full max-w-full">
        <div className="bg-white rounded shadow-md w-full h-full py-7 lg:px-24 px-8">
          <h1 className="logoText text-[#0066FF] text-3xl lg:text-4xl font-bold mb-4 text-center lg:text-left">
            ai<span className="text-black">de</span>ja
          </h1>
          <h2 className="text-2xl lg:text-3xl font-extrabold text-left mb-2">
            Verification
          </h2>
          <p className="text-lg lg:text-xl font-medium my-4">
            Verify your email address
          </p>
          <p className="text-sm lg:text-base mb-6">
            We sent you a code to verify your email address
          </p>
          <p className="text-sm lg:text-base mb-4">
            Sent to <span className="underline">{email}</span>
          </p>
          <div className="flex justify-left mb-8 space-x-4">
            {verificationCode.map((code, index) => (
              <input
                key={index}
                id={`code-${index}`}
                type="text"
                className="w-16 h-14 lg:w-[100px] lg:h-18  border border-[#0066FF] text-center text-2xl rounded-[12px] mx-1"
                value={code}
                onChange={(e) => handleChange(index, e.target.value)}
                maxLength={1}
              />
            ))}
          </div>
          <div className="flex flex-col lg:flex-row items-center mb-4 space-y-4 lg:space-y-0 lg:space-x-4">
            <button
              onClick={handleVerify}
              className="bg-[#0066FF] text-white py-3 px-8 rounded-full h-14 w-full lg:w-[40%]"
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify"}
            </button>
            <p className="text-center flex flex-col lg:flex-row items-center">
              <span className="pb-3 lg:pb-0">I didn’t receive a code</span>
              <button
                onClick={handleResendToken}
                className="text-blue-500 lg:ml-2 underline font-bold"
                disabled={resending}
              >
                {resending ? "Resending..." : "Resend"}
              </button>
            </p>
          </div>
        </div>
      </div>

      {/* Image Section */}
      <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-200 max-w-lg">
        <img src={ConfirmationImage} alt="Email Confirmation" className="h-full w-full object-cover" />
      </div>
    </div>
  );
};

export default EmailConfirmation;
