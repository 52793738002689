import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import EmailConfirmation from "./pages/EmailComfirmatin";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import PasswordConfirmation from "./pages/PasswordConfirmation";
import MainLayout from "./components/MainLayout";

const App: React.FC = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // const isAuthenticated = !!localStorage.getItem("authToken");
  // const isVerified = localStorage.getItem("isVerified"); // Assuming you store this after email verification

  return (
    <Routes>
        <Route path="/" element={isAuthenticated ?  <MainLayout />:<Login /> } />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset-request" element={<ForgotPassword />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/email_confirmation" element={<EmailConfirmation />} />
        <Route path="/reset_password_confirmation" element={<PasswordConfirmation />} />
        
        
        <Route path="/dashboard/*" element={ <MainLayout /> } />
        <Route path="/signout" element={<Navigate to="/" />} />
      </Routes>
  );
};

export default App;
