import React, { useEffect, useState, useRef, CSSProperties } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // Corrected import statement
import goggle from "../Assets/Images/goggleLogo.png";
import loginImg from "../Assets/Images/LoginImg.png";
import logoImg from "../Assets/Images/logo.png";
import { API_URL } from "../constants";
import { useGoogleLogin } from '@react-oauth/google';

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [googleButtonStyles, setGoogleButtonStyles] = useState<CSSProperties>({});
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const updateGoogleButtonStyles = () => {
    if (window.innerWidth < 500) {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "0%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    } else if (window.innerWidth < 700) {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "28%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    } else {
      setGoogleButtonStyles({
        cursor: "pointer",
        height: "50px",
        width: "100%",
        left: "28%",
        top: "10px",
        position: "absolute",
        opacity: "0",
      });
    }
  };

  useEffect(() => {
    updateGoogleButtonStyles();
    window.addEventListener("resize", updateGoogleButtonStyles);
    return () => {
      window.removeEventListener("resize", updateGoogleButtonStyles);
    };
  }, []);

  const handleGoogleResponse = async (response: any) => {
    const userObject: any = jwtDecode(response.credential); // Corrected usage

    const payload = {
      displayName: userObject.name,
      email: userObject.email,
      id: userObject.sub,
      photoUrl: userObject.picture,
    };

    try {
      const googleResponse = await axios.post(
        `${API_URL}/auth/google`,
        payload
      );

      const { token, user } = googleResponse.data.data;

      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user.firstname));

      navigate("/dashboard");
      toast.success("Google Sign-In successful");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password,
      });
  
      const { token, user } = response.data.data;
  
      // Save token and user info to localStorage
      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user.firstname));
  

       // Update isAuthenticated state in App
    setIsAuthenticated(true);  // Assuming you pass this function down as a prop


      // Ensure the state is updated before navigating
      setIsLoading(false);
      toast.success("Login successful");
      
      // Navigate to dashboard
      navigate("/dashboard");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message || "An error occurred";
  
      if (error.response?.data?.error?.code === "email-unverified") {
        setShowResendModal(true);
      } else if (error.message === "Network Error") {
        toast.error("Network Error, check your internet");
      } else {
        console.log(error);
        toast.error(errorMessage);
      }
  
      setIsLoading(false);
    }
  };
  

  const handleResendToken = async () => {
    try {
      await axios.post(
        `${API_URL}/auth/resend-token`,
        { email }
      );

      toast.success("Verification token resent successfully");
      navigate(
        `/email_confirmation?email=${encodeURIComponent(
          email
        )}&verificationType=emailVerification`
      );
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.error?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const clickGoogleSignIn = useGoogleLogin({
    flow: 'auth-code',
    scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: async (codeResponse) => {
      console.log('codeResponse');
      console.log(codeResponse);
      const response = await axios.post(
        `${API_URL}/auth/google`, {
        ...codeResponse,
      });

      let token = response.data.data.token;
      let user = response.data.data.user;
      console.log('tokens');
      console.log(token);

      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user.firstname));

      navigate("/dashboard");
      console.log("naviagted to dashboard")
      toast.success("Login successful");
    },
    onError:( errorResponse) => {
      
      
      console.log(errorResponse)
      toast.error("Login successful");
    },
  });


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="min-h-screen flex max-w-screen-2xl">
      <div className="flex-1 flex justify-center items-center bg-gray-100 w-full max-w-full">
        <div className="bg-white rounded shadow-md w-full h-full py-7 lg:px-24 px-8 flex flex-col">
          <h1 className="logoText text-[#0066FF]">
            <div className="flex justify-center items-center w-40 h-40  overflow-hidden">
              <img
                src={logoImg}
                alt="logo image"
                className="object-contain w-full h-full"
              />
            </div>

            {/* ai<span className="text-black">de</span>ja */}
          </h1>
          <h1 className="text-3xl font-extrabold text-left mb-2">
            Welcome to aideja
          </h1>
          <p className="text-left mb-4 font-[400]">
            Don't have an account?{" "}
            <Link to={"/register"} className="text-blue-500">
              Signup
            </Link>
          </p>
          <div className="relative w-full flex items-center justify-center px-3 py-3 rounded-[40px] mb-4 bg-white custom-button-border text-black h-[64px] transition-colors duration-300 ease-in-out hover:bg-gray-800 hover:text-white">
  <div
    ref={googleButtonRef}
    data-text="Sign in with Google"
    id="google-signin-button"
    style={googleButtonStyles}
    onClick={() => clickGoogleSignIn()}
    className="absolute inset-0 z-10" // Ensures it's above other content
  ></div>
  <div className="flex items-center space-x-2"> {/* Flex container for icon and text */}
    <img src={goggle} alt="Google Icon" className="w-6 h-6" />
    <span>Continue With Google</span>
  </div>
</div>




          <div className="flex items-center my-4 pt-2">
            <div className="flex-grow border-t border-[#66666640] border-[2px]"></div>
            <span className="mx-4 text-gray-500">OR</span>
            <div className="flex-grow border-t border-[2px] border-[#66666640]"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-[#666666] mb-1 text-left font-normal">
                Email
              </label>
              <input
                type="email"
                className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-8">
              <div className="flex items-center justify-between mt-2">
                <label className="block text-[#666666] text-left font-normal">
                  Password
                </label>
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="text-gray-500 bg-white my-0"
                >
                  <div className="flex">
                    <span className="pt-1">
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    <span className="mx-2">
                      {passwordVisible ? "Hide" : "Show"}
                    </span>
                  </div>
                </button>
              </div>
              <input
                type={passwordVisible ? "text" : "password"}
                className="w-full border border-[#66666659] px-3 py-3 rounded-xl h-[56px]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className={`w-[256px] bg-blue-500 text-white py-2 rounded-[32px] hover:bg-blue-600 text-center h-[64px] mt-5  ${isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#0066FF] hover:bg-[#0056b3]"
                }`}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Login"}
            </button>
            <div className="flex justify-between mt-2">
              <div className="block text-[#666666] mb-1 text-left font-normal">
                <Link to={"/password-reset-request"} className="">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-200 max-w-md">
        <img
          src={loginImg}
          alt="login image"
          className="object-cover w-full h-full"
        />
      </div>
      {showResendModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-md">
            <h2 className="text-2xl font-bold mb-4">
              Resend Verification Token
            </h2>
            <p className="mb-4">
              Your account is not verified. You will be redirected to the email
              confirmation page to verify your account.
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleResendToken}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                Resend Token
              </button>
              <button
                onClick={() => setShowResendModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
