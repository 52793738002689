import React, { useEffect, useState } from "react";
import SearchFurniture from "../components/SelectFurniture";
// import { Link, useSearchParams, useNavigate } from "react-router-dom";
// import axios from "axios";
// import ConfirmationImage from "../Assets/Images/emailComfirm.png";

const Dashboard: React.FC = () => {

  return (
    <div className="">
     <SearchFurniture />
    </div>
  );
};

export default Dashboard;
