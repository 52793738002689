import React, { useState, useRef, useEffect, FC } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";
import Select from 'react-select';

interface CustomDropdownProps {
  label: string;
  options: string[];
  value: string;
  onChange: (value: string) => void;

}

const CustomDropdown: FC<CustomDropdownProps> = ({
  label,
  options,
  value,
  onChange,
 
}) => {
  


  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div ref={dropdownRef} className="relative">
      <label className="block text-lg font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative">
        <button
          onClick={handleToggle}
          className="w-full border border-gray-300 px-[2px] md:px-3 py-3 rounded-xl h-14 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-150 flex justify-between items-center"
        >
          <span className=" text-[15.5px]">
            {value ? value : `Select ${label.toLowerCase()}`}
          </span>
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {isOpen && (
          <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-xl shadow-lg z-10 max-h-60 overflow-y-auto">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`px-4 py-2 hover:bg-gray-200 cursor-pointer flex items-center ${value === option ? "bg-blue-100" : ""
                  }`}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface SearchableDropdownProps {
  label: string;
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const SearchableDropdown: FC<SearchableDropdownProps> = ({ label, options, value, onChange }) => {
  const formattedOptions = options.map(option => ({ value: option, label: option }));

  return (
    <div className="relative">
      <label className="block text-lg font-medium text-gray-700 mb-2">
        {label}
      </label>
      <Select
        options={formattedOptions}
        value={formattedOptions.find(option => option.value === value)}
        onChange={selectedOption => onChange(selectedOption ? selectedOption.value : '')}
        isSearchable
        classNamePrefix="react-select"
      />
    </div>
  );
};

const FilterPage: FC = () => {
  const [roomType, setRoomType] = useState<string>("");
  const [roomSize, setRoomSize] = useState<string>("");
  const [preferredColor, setPreferredColor] = useState<string>("");
  const [preferredStyle, setPreferredStyle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFilterData = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (!token) {
          throw new Error("Token not found");
        }

        const response = await axios.get(
          `${API_URL}/user/filter`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data.data);
        setFilterOptions(response.data.data);
        setLoading(false);
      } catch (error: any) {
        const errorMsg =
          error.response?.data?.error?.message || "Error fetching filtered data.";
        toast.error(errorMsg);
        console.error("Error fetching filtered data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  
  const roomTypeOptions = [
    "Living Room - Small",
    "Living Room - Large",
    "Dining Room",
    "Bedroom - Small",
    "Bedroom - Large",
    "Gaming/Office Room",
    "Home Theater",
    "Kids Room",
  ];
 
  const colorOptions = [
    "Anthracite",
    "Antique",
    "Beige",
    "Black",
    "Blue",
    "Brown",
    "Charcoal",
    "Chocolate",
    "Dark",
    "Desk",
    "Distressed",
    "Grey",
    "Ivory",
    "Light",
    "Mahogany",
    "Natural",
    "Navy",
    "Oak",
    "Slate",
    "Tan",
    "Taupe",
    "Walnut",
    "Weathered",
    "White",
    "Other"
];
  const getToken = (): string | null => {
    return localStorage.getItem("authToken");
  };

  const getFirstName = (): string | null => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      const firstname = user;

      if (typeof firstname === "string" && firstname.length > 0) {
        return firstname.charAt(0).toUpperCase() + firstname.slice(1);
      } else {
        return null;
      }
    }
    return null;
  };

  const token = getToken();
  if (!token) {
    throw new Error("Token not found");
  }

  const firstName = getFirstName() || "User";

  const handleFilterSubmit = async () => {
    if (!roomType || !preferredColor || !preferredStyle) {
      toast.error("Please fill in all fields before searching.");
      return;
    }
    setLoading(true);
    try {
      const filterData = {
        roomType,
        preferredColor,
        preferredStyle,
      };
      let items
      localStorage.setItem("filterData", JSON.stringify(filterData));
      if (roomType == "Living Room - Small") {
        items = ["Coffee Tables", "Couches, Sofas & Lounges", "Storage & Wall Units", "TV Units"]
      }

      if (roomType == "Living Room - Large") {
        items = ["Armchairs & Occasional Chairs", "Coffee Tables", "Couches, Sofas & Lounges", "Storage & Wall Units", "TV Units"]
      }

      if (roomType == "Dining Room") {
        items = ["Sideboards & Buffets", "Dining Chairs", "Dining Tables"]
      }
      if (roomType == "Bedroom - Small") {
        items = ["Bed Frames", "Bedside Tables", "Chest of Drawers", "Wardrobes"]
      }
      if (roomType == "Bedroom - Large") {
        items = ["Armchairs & Occasional Chairs", "Bed Frames", "Bedside Tables", "Chest of Drawers", "Wardrobes"]
      }
      if (roomType == "Gaming/Office Room") {
        items = ["Bookshelves & Bookcases", "Desks & Tables", "Office Chairs", "Office Storages",]
      }
      if (roomType == "Home Theater") {
        items = ["Armchairs & Occasional Chairs", "Couches, Sofas & Lounges", "Side Tables", "TV Units",]
      }
      if (roomType == "Kids Room") {
        items = ["Kids' Beds", "Kids' Cabinets", "Kids' Chairs & Stools", "Kids' Tables & Desks"]
      }
      localStorage.setItem("items", JSON.stringify(items));
      localStorage.setItem("preferredStyle", preferredStyle);
      localStorage.setItem("preferredColor", preferredColor);
      const limit = 30;
      const count = 1;
      const response = await axios.get(
        `${API_URL}/user/furnitures`,
        {
          params: {
            limit,
            count,
            items
            // style:preferredStyle
          },
          headers: {
            Authorization: token,
          },
        }
      );
    
      toast.success("Data fetched successfully!");
      console.log(response.data.data)
      navigate("/dashboard/filtered-results", {
        state: { filteredData: response.data.data },
      });
    } catch (error: any) {
      const errorMsg =
        error.response?.data?.error?.message || "Error fetching filtered data.";
      toast.error(errorMsg);
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="text-start">
        <h2 className="text-[40px] font-[800]">Hello {firstName},</h2>
        <p className="text-xl mb-4">
          What furniture would you like to see today?
        </p>
      </div>
      <div className="min-h-screen flex flex-col items-center py-8">
        <div className="w-full max-w-4xl bg-white rounded-[24px] p-1">
          <div className="bg-white p-6 mb-8 w-full">
            <div className="flex justify-between mb-4">
              <h3 className="text-[25px] font-[800] mb-3 text-start">Filter</h3>
              <button
                className="text-blue-500"
                onClick={() => {
                  setRoomType("");
                  setRoomSize("");
                  setPreferredColor("");
                  setPreferredStyle("");
                }}
              >
                Clear all
              </button>
            </div>
            <div className="space-y-6">
              <CustomDropdown
                label="Room Type"
                options={roomTypeOptions}
                value={roomType}
                onChange={(value) => setRoomType(value)}
              />
              {filterOptions && (
                <>
                  <SearchableDropdown
                    label="Preferred Color"
                    options={colorOptions}
                    value={preferredColor}
                    onChange={(value) => setPreferredColor(value)}
                  
                  />
                  <CustomDropdown
                    label="Preferred Style"
                    options={filterOptions.uniqueStyles}
                    value={preferredStyle}
                    onChange={(value) => setPreferredStyle(value)}
                  />
                </>
              )}
            </div>
            <button
              onClick={handleFilterSubmit}
              className="mt-6 w-full bg-indigo-600 text-white px-4 py-5 rounded-full hover:bg-indigo-700 transition ease-in-out duration-150 flex items-center justify-center disabled:bg-indigo-300"
              disabled={
                !roomType || !preferredColor || !preferredStyle || loading
              }
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-white h-6 w-6"></div>
              ) : (
                "Select Furniture"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};




export default FilterPage;
